// Embedded fonts

/* ubuntu-400normal - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/ubuntu-latin-400.eot'); /* IE9 Compat Modes */
  src:
    local('Ubuntu'),
    local('Ubuntu Regular'),
    local('Ubuntu-Regular'),
    url('../fonts/ubuntu-latin-400.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/ubuntu-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/ubuntu-latin-400.woff') format('woff'), /* Modern Browsers */
    url('../fonts/ubuntu-latin-400.svg#ubuntu') format('svg'); /* Legacy iOS */
}
