#game-dashboard {
  width: 230px;
  // Prevent grid from shifting awkwardly when game controls disappear/reappear
  min-height: 60px;
  margin-bottom: 10px;
  label, button {
    display: inline-block;
    margin: 0 6px;
    font-size: $dashboard-text-size;
    @include if-hybrid-layout() {
      font-size: $dashboard-text-size-hybrid;
    }
  }
  @include if-hybrid-layout() {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

#game-message {
  font-size: $dashboard-text-size;
  margin: 0;
  padding-bottom: 10px;
  line-height: 1.5;
  @include if-hybrid-layout() {
    font-size: $dashboard-text-size-hybrid;
  }
  // Increase the click area of the <label> element to include the white space
  // underneath the game message
  .prompting-for-input & {
    padding-bottom: 0;
    label {
      padding-bottom: 10px;
    }
  }
}
