// Core containers

body {
  min-width: $page-min-width-compact;
  margin: 0;
  padding: 0;
  // Ensure that the absolutely-positioned nav links do not get pushed out of
  // the bounds of the <body> due to the <h1> margin-top
  padding-top: 1px;
  font-family: $sans-serif;
  text-align: center;
  overflow-y: scroll;
  @include dark-mode() {
    background-color: $app-background-color-dark;
    color: $app-color-dark;
  }
}

// Ensure that the <main> element renders correctly on browsers that don't fully
// support it
main {
  display: block;
}

#game {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: $page-max-width-hybrid;
  margin: 0 auto;
  margin-top: $page-spacing-top;
  padding: 0 $page-spacing-x;
  font-size: 0;
  @include if-hybrid-layout() {
    display: inline-block;
    margin-top: 0;
    padding: 0;
    text-align: center;
  }
}

a {
  color: $input-focus-border-color;
  text-decoration: none;
}
