#dashboard-controls {
  button {
    border: solid 1px rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    padding: 4px 6px;
    background-color: $button-background-color;
    font-family: $sans-serif;
    color: #fff;
    &:not([disabled]):hover:active,
    &:not([disabled]):focus:active {
      background-color: lighten($button-background-color, 5%);
    }
    &:focus {
      outline-width: 0;
    }
    &.warn {
      background-color: $button-warn-background-color;
      &:not([disabled]):hover:active,
      &:not([disabled]):focus:active {
        background-color: desaturate(lighten($button-warn-background-color, 10%), 10%);
      }
    }
    &:disabled {
      background-color: $button-disabled-background-color;
    }
  }
  a {
    font-size: 14px;
  }
  .go-back {
    margin-left: 5px;
  }
}

input[type='text'] {
  -webkit-appearance: none;
  border-radius: 3px;
  padding: 4px 8px;
  border: solid 1px #aaa;
  box-sizing: border-box;
  font-size: 16px;
  text-align: center;
  &:focus {
    outline-width: 0;
    border-color: $input-focus-border-color;
    box-shadow: 0 0 3px 1px $input-focus-box-shadow-color;
  }
}

#new-player-name {
  display: block;
  width: 150px;
  margin: 0 auto;
  margin-bottom: 10px;
}

#share-controls {
  display: flex;
  justify-content: center;
  align-items: stretch;
  position: relative;
  margin-bottom: 10px;
}
#share-link {
  width: 150px;
  font-size: 12px;
}
#copy-share-link:last-of-type {
  margin-right: 0;
  transition: background-color 1000ms ease-in-out;
  &:hover:active {
    background-color: $button-action-background-color;
    transition-duration: 0ms;
  }
}
