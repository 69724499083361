#reaction-picker {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  z-index: 5;
  width: 220px;
  margin: 0 auto;
  user-select: none;
}
.available-reaction {
  flex-basis: 25%;
  padding-bottom: 10px;
}
.available-reaction-symbol {
  display: inline-block;
  font-size: 36px;
  line-height: 1;
  cursor: default;
  transition-property: transform, filter;
  transition-duration: 150ms, 250ms;
  transition-timing-function: ease-in-out;
  &:hover {
    transform: scale(1.15);
  }
  &:hover:active {
    filter: brightness(0.75);
    transition-duration: 150ms, 0ms;
  }
}
